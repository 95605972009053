import React, { Component } from "react";
import './Home.css';

export class Home extends Component {
    render() {
        return(
          <main>
            <section id="home-content">
              <div className="small">Art works by</div>
              <div className="big">Rachel Han</div>
            </section>
          </main>
        );
    }
}

export default Home;