import React, { Component } from "react";
import { Link } from 'react-router-dom';

import './Nav.css';

export class Nav extends Component {
    render() {
        return(
            <nav className="menu">
                <Link to="/about">About</Link>
                <Link to="/art-works">Art works</Link>
                <Link to="/awards">Recognitions</Link>
                <Link to="/contact">Contact</Link>
            </nav>
        );
    }
}

export default Nav;