import React, { Component } from "react";
import './Footer.css';

export class Footer extends Component {
    render() {
        return(
          <footer>
            <div className="copyright">
              &copy; 2024 Pastel Dust.
            </div>
            <div className="social-media">

            </div>
          </footer>
        );
    }
}

export default Footer;