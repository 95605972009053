import React, { Component } from "react";
import './About.css';
import profile from "../../assets/images/profile.jpg";

export class About extends Component {
    render() {
        return(
          <section id="about">
            <p className="head">BIOGRAPHY</p>
            <div className="row">
              <div className="column profile-img">
                <img src={profile} />
              </div>
              <div className="column artist-statement">
                <p>I’ve been creating art for as long as I remember, from bathtub murals to doodles in the margins of my notes. But recently, art has become something more than just a hobby— it’s an outlet that I can express myself through beyond words; it allows me to tell stories of myself and those around me, making me, and hopefully viewers, more aware of the world around them.</p>
                <p>While constructing my portfolio, I stopped to think about what my purpose behind art is. My pieces tend to focus on figures of people in my life, but also of strangers I would normally pass by, and i realized that beyond being an expressive outlet, art is something that I can utilize to create movement and change. Bringing the complex narratives of those around me out through my art, I want to create an environment that looks beyond ourselves as the main character of our narratives and become more aware of others guiding us along our stories called life.</p>
              </div>
            </div>
          </section>
        );
    }
}

export default About;