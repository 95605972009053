export const images = [
    {
        src: '/images/0030.jpg',
        width: 2797,
        height: 3736,
        title: '<b>In Honest Reflection</b>',
        description: 'Acrylic on canvas. This piece uses intricate drapery and textures to portray a self-reflective moment of conversing with myself, blending realistic details with cool, dreamy undertones for a surreal atmosphere.'
    },
    {
        src: '/images/0029.jpg',
        width: 2915,
        height: 3647,
        title: '<b>Together Through the Storm</b>',
        description: 'Acrylic on canvas. This painting captures the bond between a person and their pet that cannot be broken in any environment or circumstance. The darker tones and harsh shadows reflect the challenging environment, while the diagonal composition emphasizes the intimate connection the girl shares with her pet. As the cat gazes at the camera and his girl looks down at him, the piece conveys an unspoken understanding and care that goes beyond words words, illustrating the comfort and companionship that pets provide.'
    },
    {
        src: '/images/0031.jpg',
        width: 3063,
        height: 4200,
        title: '<b>Behind the quiet moments</b>',
        description: 'Soft pastel on colored paper. This drawing captures an intimate moment between friends, as if glimpsed through a half-open door. The soft, muted tones and unique color palette that pastels create bring out the textures of the fabric and skin, creating an vibe of quiet joy and genuineness. The perspective aims to celebrate the authenticity of friendship in unembellished moments, emphasizing the warmth and connection that come from simply being in each other’s presence.'
    },
    {
        src: '/images/0032.jpg',
        width: 2000,
        height: 1571,
        title: '<b>Storefront</b>',
        description: 'Charcoal on paper. I wanted to show a moment of connection between me and a friend in a store, emphasizing detailed facial expressions while maintaining a sketch-like, unfinished quality in the background. The contrast between the meticulous rendering of our faces and the loose lines of the surroundings showcases my artistic confidence and creativity. By focusing on this mundane moment, I aim to highlight the beauty of genuine friendship in everyday life.'
    },
    {
        src: '/images/0020.jpg',
        width: 3859,
        height: 1915,
        title: '<b>Shared Disconnection</b>',
        description: 'Acrylic on canvas. This piece examines the complexities of connection in a world of technology, highlighting the duality of promoting and inhibiting genuine connection. Utilizing a diagonal composition, I draw attention to the smartphones that control modern interactions, prompting viewers to question the authenticity of these connections. The contrasting elements of drapery and a palette of blues and greens reflect the nuanced emotions tied to our digital habits, suggesting that while technology can bring us together, it may also hinder genuine relationships.'
    },
    {
        src: '/images/0033.jpg',
        width: 1575,
        height: 2100,
        title: '<b>Dual Glow</b>',
        description: 'Pastel on paper. This self-portrait uses the interplay of blue tones and a reflected red light to explore different facets of self-awareness. Through varied textures and a focus on detail in the hair, I convey an introspective connection with myself. The contrasting colors reflect the dualities within, capturing both the visible and hidden aspects of identity.'
    },
    {
        src: '/images/0017.jpg',
        width: 2000,
        height: 1294,
        title: '<b>Walk through shadows</b>',
        description: 'Charcoal on paper. This piece aims to depict personal growth through self reflection as I watch my younger self walk through shadows, confidently at times, while hesitantly in others. I chose charcoal as my medium for its ability to create dramatic shadows that I thought matched the message well. I hope that this piece motivates viewers to know that they can make it through even the hardest of times and the darkest of shadows, and in the future, they’ll look back and feel proud of themselves. This connection between the past and the present is important in seeing how much one grows, something that’s hard to feel in the moment.'
    },
    {
        src: '/images/0002.jpg',
        width: 2813,
        height: 3820,
        title: '<b>Timeless Connections</b>',
        description: 'Acrylic on canvas. This painting captures a moment of comfort as two figures are surrounded by soft textures of clothing and pillows. By employing a muted palette of blues, greens, and creams, I create a sense of warmth and security. The layered brushwork enhances the softness of the scene, while the choice of color and texture reflects the enduring nature of human connections, illustrating how love and comfort persist beyond physical presence. Through this intimate portrayal, I convey the significance of the bonds we form with those we cherish.'
    },
    {
        src: '/images/0000.jpg',
        width: 3600,
        height: 2700,
        title: '<b>Ripple Effect</b>',
        description: 'Acrylic on canvas.'
    },
    {
        src: '/images/0001.jpg',
        width: 4206,
        height: 3300,
        title: '<b>No Signal</b>',
        description: 'Digital painting. Emphasized human dependence on technology, even in the middle of nowhere'
    },
    
    {
        src: '/images/0003.jpg',
        width: 3600,
        height: 2700,
        title: '<b>Bird of the wild</b>',
        description: 'Acrylic on canvas.'
    },
    {
        src: '/images/0004.jpg',
        width: 2388,
        height: 1481,
        title: '<b>Get out of my way!</b>',
        description: 'Digital painting. Overcoming obsticle in life.'
    },
    {
        src: '/images/0005.jpg',
        width: 4800,
        height: 3600,
        title: '<b>August</b>',
        description: 'Pastel on color paper.'
    },
    {
        src: '/images/0006.jpg',
        width: 3300,
        height: 4400,
        title: '<b>The raw truth</b>',
        description: 'Digital painting. A girl holds raw meat with a wild expression, capturing the absurdity of school life and its expectations.'
    },
    {
        src: '/images/0007.jpg',
        width: 3814,
        height: 1904,
        title: '<b>One who fell a sleep</b>',
        description: 'Acrylic on canvas. Encourage viewers to pay close attention to details.'
    },
    {
        src: '/images/0026.jpg',
        width: 4500,
        height: 3375,
        title: 'title26',
        description: '<b>test</b>'
    },
    {
        src: '/images/0008.jpg',
        width: 3281,
        height: 4200,
        title: '<b>Nurture</b>',
        description: 'Digital self portrait. Nurturing my childhood.'
    },
    {
        src: '/images/0009.jpg',
        width: 3362,
        height: 4200,
        title: '<b>Space sleep</b>',
        description: 'Digital painting. Exploring potential for technological development in space travel.'
    },
    {
        src: '/images/0010.jpg',
        width: 1663,
        height: 3300,
        title: '<b>Solo Dining</b>',
        description: 'Digital painting. Expressing the loneness even in a space full of people.'
    },
    {
        src: '/images/0011.jpg',
        width: 782,
        height: 1031,
        title: '<b>In tune with peace</b>',
        description: 'Digital self portrait. Exploring the peaceful joy of learning guitar, symbolizing curiocity and openness to new hobby.'
    },
    {
        src: '/images/0012.jpg',
        width: 4786,
        height: 6000,
        title: '<b>Lockdown loyalty</b>',
        description: 'Digital painting. A girl with a mask enbracing her dog capturing the comfort pets provided during the pandamic.'
    },
    {
        src: '/images/0013.jpg',
        width: 3541,
        height: 4500,
        title: '<b>Head in the clouds</b>',
        description: 'Digital self portrait. My head rests on clouds capturing the boundless feeling of daydreaming.'
    },
    {
        src: '/images/0014.jpg',
        width: 2975,
        height: 2515,
        title: '<b>Silent weight</b>',
        description: 'Charcoal drawing. A man sits with his head bowed, embodying the weight of depression and loneliness.'
    },
    // {
    //     src: '/images/0015.jpg',
    //     width: 2750,
    //     height: 3789,
    //     title: 'title15',
    //     description: '<b>test</b>'
    // },
    {
        src: '/images/0016.jpg',
        width: 3300,
        height: 5100,
        title: '<b>Skyline sweets</b>',
        description: 'Digital painting. A woman sits atop skyscraper, savoring a cupcake amidst the city bustle below, capturing a quiet moment in an otherwise fastpaced world.'
    },
    
    {
        src: '/images/0018.jpg',
        width: 2811,
        height: 3549,
        title: 'title18',
        description: '<b>test</b>'
    },
    {
        src: '/images/0019.jpg',
        width: 1440,
        height: 1162,
        title: 'title19',
        description: '<b>test</b>'
    },
    
    {
        src: '/images/0021.jpg',
        width: 2912,
        height: 4500,
        title: 'title21',
        description: '<b>test</b>'
    },
    {
        src: '/images/0022.jpg',
        width: 2912,
        height: 4500,
        title: 'title22',
        description: '<b>test</b>'
    },
    {
        src: '/images/0023.jpg',
        width: 1350,
        height: 1800,
        title: 'title23',
        description: '<b>test</b>'
    },
    {
        src: '/images/0024.jpg',
        width: 4198,
        height: 3300,
        title: 'title24',
        description: '<b>test</b>'
    },
    // {
    //     src: '/images/0025.jpg',
    //     width: 2550,
    //     height: 3300,
    //     title: 'title25',
    //     description: '<b>test</b>'
    // },
    
    {
        src: '/images/0027.jpg',
        width: 1787,
        height: 2212,
        title: 'title27',
        description: '<b>test</b>'
    },
    {
        src: '/images/0028.jpg',
        width: 3300,
        height: 4200,
        title: 'title28',
        description: '<b>test</b>'
    }

    
    
]