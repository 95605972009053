import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { Footer } from '../Footer/Footer';
import { Header } from "../Header/Header";
import { Home } from "../Home/Home";
import { Arts } from "../Arts/Arts";
import { Awards } from "../Awards/Awards";
import { About } from "../About/About";
import { Contact } from "../Contact/Contact";

export class App extends Component {
  render() {
    return(
      <div className="App">
        <BrowserRouter>
          <Header />
          <Routes> 
            <Route exact path="/" element={<Home/>}/> 
            <Route path="/awards" element={<Awards/>}/>
            <Route path="/art-works" element={<Arts/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/contact" element={<Contact/>}/>
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
