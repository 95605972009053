import React, { Component } from "react";
import './Awards.css';
import profile from "../../assets/images/profile.jpg";

export class Awards extends Component {
    render() {
        return(
          <section id="awards">
            <p className="head">Recognitions</p>
            <div className="list">
              <table>
                <tbody>
                  <tr>
                    <td className="col1">2024 June</td>
                    <td>Cat Exhibition</td>
                  </tr>
                  <tr>
                    <td>2024 May</td>
                    <td>Young Visionaries of the East Side Exhibition</td>
                  </tr>
                  <tr>
                    <td>2024 May</td>
                    <td>Young Visionaries of the East Side Exhibition</td>
                  </tr>
                  <tr>
                    <td>2024 May</td>
                    <td>Young Visionaries of the East Side Exhibition</td>
                  </tr>
                </tbody>
              </table>
              Summer 2022 celebrating arts high merit award “no signal”
Fall 2022 celebrating arts high merit award “disoriented”
September 2022 you name it exhibition 
Spring 2023 celebrating arts high merit award “little bird”
Summer 2023 “the two who fell asleep” high merit award 
Fall 2023 “ripple effect” high merit award 
Spring 2024
Summer 2024
April 2023 young visionaries of the east side exhibition 
New York Times 2023 international multimedia challenge runner up “shared disconnection” 
Top 35/4000
The Joongang Korea Daily 2023 student art contest honorable mention 
The Joongang Korea Daily 2023 student video contest 2nd place 
National art honor societies juried exhibition 2024 “shared disconnection” -125 works chosen out of 2800 submissions 
Unsung heroes 2024 national grand prize 

              
            </div>
          </section>
        );
    }
}

export default Awards;