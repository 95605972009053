import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Nav } from '../Nav/Nav';
import './Header.css';
import logo from "../../assets/logos/logo2.png";

export class Header extends Component {
    render() {
        return(
          <header>
            <div className="head-link">
              <Link to="/"><img src={logo} /></Link>
            </div>
            <div className="nav">
              <Nav/>
            </div>
          </header>
        );
    }
}

export default Header;