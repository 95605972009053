
import { FC, useEffect } from 'react';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgShare from 'lightgallery/plugins/share';
import lgHash from 'lightgallery/plugins/hash';
import './Arts.scss';
import { images } from '../Config/images';

export const Arts: FC = () => {
  
  return (
    <section id="arts">
      <p className="head">My Art Works</p>
      <div className="grid-wrapper">
      <LightGallery
        plugins={[lgZoom, lgShare, lgHash]}
        speed={800}
      >
        {
          images.map((image, i) => {
            let classVal = '';
            switch (i) {
              case 0:
              case 2:
              case 7:
              case 10:
              case 16:
              case 20:
              case 24:
              case 26:
              case 28:
              case 27:
              case 33:
                classVal = 'tall'
                break;
              case 4:
              case 14:
              case 19:
              case 32:
                classVal = 'wide'
                break;
                
              case 15:
              //case 24:
                classVal = 'big'
                break;
            }
            return (
              <a
                data-lg-size={images[i].width + "-" + images[i].height}
                data-src={images[i].src}
                data-sub-html={images[i].title + "<br />" + images[i].description}
                className={classVal}
              >
                <img src={images[i].src} alt={images[i].title} />
              </a>
            );
          })
        }        
      </LightGallery>
      </div>
    </section>
  );
};

